<template>
  <div class="col-12 col-lg-8 c g">
    <div class="card">
        <div class="card-body">
            API & Docs (<span class="text-danger">الشرح باللغة العربية فقط حتى الآن</span>) <a href="https://documenter.getpostman.com/view/14352330/2sA3Qv8AYd" target="_blank" class="btn btn-sm btn-warning">Postman Here 👋 <i class="fa fa-external-link"></i></a>
        </div>
        <div class="card-footer">
            <strong class="g text-danger">توضيح اسلوب الاستعلامات</strong>
            <app-collapse>
              <app-collapse-item title="الردود الخاصة بالHTTP">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <br>
                      يرحى ملاحظة ان اي رد سيكون عبارة عن <code>status</code> و <code>response</code> بصيغة <code>json</code>.
                      <br>
                      <br>
                      <code>status: 100</code> تعني نجاح العملية, ويكون ال response عبارة عن نص (string) او مصفوفة (array).
                      <br>
                      <br>
                      <code>status: 200</code> تعني فشل العملية, ويكون سبب الفشل في ال response ودائماً يكون نص (string).
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/1.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
            </app-collapse>
            <br>
            <strong class="g text-danger">تسجيل الدخول والبيانات الاساسية</strong>
            <app-collapse>
              <app-collapse-item title="تسجيل الدخول والحصول على JWT">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/user/auth/login</code><br><br>
                      يمكنك تسجيل الدخول والحصول على ال JWT بعد تفعيل الحساب.
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/1.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
            </app-collapse>
            <br>
            <strong class="g text-danger">اجهزة البصمة</strong>
            <app-collapse>
              <app-collapse-item title="اضافة جهاز">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/add</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>serial_number</code>*<br><br>
                      <code>title</code> اختياري<br><br>
                      <code>lang</code> اللغة التي تريد ان يتم الرد بها بالخطأ. وهي اختيارية.<br><br>
                      <code>timezone</code> للسعودية استعمل <code>3</code> ولمصر <code>2</code><br><br>
                      <code>section_id</code> رقم القسم وهو اختياري.
                      <hr>
                      الرد:<br><br>
                      <code>title</code><br><br>
                      <code>serial_number</code><br><br>
                      <code>token</code> وهو اهم عنصر معنا لانه الكود الذي تستعمله في جميع التعاملات مع جهاز البصمة.<br><br>
                      <code>server</code> الـIP المطلوب وضعه في جهاز البصمة<br><br>
                      <code>port</code> المطلوب وضعه في جهاز البصمة مع ال IP<br><br>
                      <code>created_date</code>
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/2.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="استعراض الاجهزة/بحث عن اجهزة">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/list</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>q</code> يمكنك كتابة هنا الـserial_number او عنوان الجهاز للبحث٫ اتركه فارغاً لعرض الكل.<br><br>
                      <code>section_id</code> رقم القسم وهو اختياري.
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/3.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="رفع تقرير يوم او فترة من الجهاز">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/upload</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>token</code>*<br><br>
                      <code>from</code> التاريخ من، ويكتب بالصيغة الموضحة مع ارفاق الساعات والدقائق والثواني.<br><br>
                      <code>to</code> التاريخ الى، ويكتب بالصيغة الموضحة مع ارفاق الساعات والدقائق والثواني.
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/4.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="عرض التقارير المرفوعة لجهاز">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/logs</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>token</code>*<br><br>
                      - افتراضي يعرض احدث 100 سجل، اذا كنت تريد الاستعلام عن تقرير يوم او فترة:<br><br>
                      <code>from</code> التاريخ من (ويكون بالصيغة 2020-01-01 01:00:00)<br><br>
                      <code>to</code> التاريخ الى (ويكون بالصيغة 2020-01-01 01:00:00)<br><br>
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/5.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="رفع بيانات الجهاز للنظام (البصمات والمستخدمين)">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/sync-upload</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>token</code>*<br><br>
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/6.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="تنزيل البيانات من النظام للجهاز">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/sync-download</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>token</code>*<br><br>
                      <code>custom_serial_number</code> اذا كنت تريد بيانات جهاز معين فقط هي التي تنزل على الجهاز قم بوضع ال serial_number الخاص به، او اتركه فارغاً لتنزيل جميع البيانات المرفوعة من كل الاجهزة على الجهاز المطلوب.<br><br>
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/7.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="استعراض المستخدمين من الجهاز">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/users</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>token</code>*
                      <hr>
                      الرد:<br><br>
                      <code>pri</code> الصحلاية 0 تعني مستخدم عادي، 14 تعني مسؤول
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/8.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="حذف المستخدمين من الجهاز">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/users/delete</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>token</code>*<br><br>
                      <code>id</code>* رقم الهوية
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/9.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="اضافة المستخدمين الى الجهاز">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/users/add</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>token</code>*<br><br>
                      <code>id</code>* رقم الهوية<br><br>
                      <code>name</code>* الاسم<br><br>
                      <code>pri</code>* الصلاحية 0 للمستخدم، 14 للمسؤول
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/14.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="اعدادات الـcallback للجهاز">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/users/edit-callback</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>token</code>*<br><br>
                      <code>method</code>* GET or POST<br><br>
                      <code>retry_after</code>* اعادة المحاولة اذا فشل ارسال التقارير بعد (بالدقائق)
                      <hr>
                      ما يتم ارساله:
                      <br><br>
                      <code>sn</code> الرقم التسلسلي للجهاز<br><br>
                      <code>store_date</code> التوقيت الذي استلمنا فيه التقارير في موقعنا (ربما يصلك التقرير بعد مدة كبيرة اذا فشل الارسال، فعندها تعرف التاريخ)<br><br>
                      <code>records</code> وهي التقارير نفسها، وتكون JSON محولة لنص، <br>
                      لذا يجب استعمال JSON.parse(records) في Javascript او json_decode($records) في PHP.
                      <br><br>
                      مثال للـrecord: 
                      <br>
<code>                {
                  id: "123",
                  time: "2015-07-30 15:16:30",
                  status: 1,
                  verify: 1
                }</code>
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/9.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="حذف الجهاز">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/delete</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>token</code>*
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/11.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="استعراض الاقسام">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/sections</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/12.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
              <app-collapse-item title="اضافة قسم">
                <div class="row">
                  <div class="col-12 col-lg-6">
                    <h5>
                      <code>https://serverbioraw.brmja.com/devices/sections/add</code><br><br>
                      الحقول المطلوبة:<br><br>
                      <code>jwt</code>*<br><br>
                      <code>title</code>*
                    </h5>
                  </div>
                  <div class="col-12 col-lg-6">
                    <img :src="require('@/assets/images/docs/13.png')" onclick="window.open(this.src)">
                  </div>
                </div>
              </app-collapse-item>
            </app-collapse>
        </div>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
}
</script>

<style>
  .card-footer img{
    max-width: 100%;
    border: 2px solid darkred;
    border-radius: 5px;
    cursor: pointer;
  }
  .card-footer img:hover{
    border: 2px solid lightgreen;
  }
</style>